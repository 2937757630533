
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactSVG } from "react-svg";
import { suite_private, suite_public, suite_org, land_suite, oee, aiassistant, demoapp3 } from "../Images/Images";
import SuiteCardHome from "../cards/SuiteCardHome"
import { useQuery } from "react-query";
import { getSuites } from "../../services/Api";
import PageLoader from "../loaders/PageLoader";

function SimpleSlider() {

  const { isLoading, data, error, isError, isSuccess } = useQuery({
    queryKey: ["suites"],
    queryFn: () => getSuites(),
    onSuccess: (data) => console.log(data)
  })

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };

  const suiteMaster = [{
    suiteUid: 1,
    scope: "ORGANISATION",
    image: oee,
    user: { username: "theopenfactory" },
    downloads: "1,234",
    version: "1.0.0",
    architecture: "linux/arm64",
    description: "This is a placeholder suite for demonstration purpose",
    suiteName: "Demo Suite 1"
  },
  {
    suiteUid: 2,
    scope: "PRIVATE",
    image: aiassistant,
    user: { username: "theopenfactory" },
    downloads: "1,234",
    version: "1.0.0",
    architecture: "linux/amd64",
    description: "This is a placeholder suite for demonstration purpose",
    suiteName: "Demo Suite 2"
  },
  {
    suiteUid: 3,
    scope: "PUBLIC",
    image: demoapp3,
    user: { username: "theopenfactory" },
    downloads: "1,234",
    version: "1.0.0",
    architecture: "linux/arm64",
    description: "This is a placeholder suite for demonstration purpose",
    suiteName: "Demo Suite 3"
  }]

  // suite = suiteMaster[index]

  return (
    <div className="w-100 text-center">
      <Slider {...settings}>
        {/* <img className="d-block"
          src={land_suite}
          alt="First slide"
        /> */}
        {
          // isLoading ?
          // <PageLoader></PageLoader>
          // :
          // <>
          //   <div key={0}>
          //     <SuiteCardHome index={0} />
          //     <SuiteCardHome index={1} />
          //     <SuiteCardHome index={2} />

          //   </div>
          // </>
          // isSuccess ?
          suiteMaster?.map(suiteMaster => {
            return (
              <div key={suiteMaster?.suiteUid}>
                <SuiteCardHome suite={
                  suiteMaster
                } />
              </div>
            )

          })
          // :
          // <>
          //   Oops ! could not get suites
          // </>
        }

        {/* <div>
      <SuiteCardHome suite={{
          scope:'PUBLIC',
          imageURL:'https://5.imimg.com/data5/SELLER/Default/2021/7/NE/LV/HH/48426159/samsung-galaxy-fold-mobile-phone.jpg',
          suiteName:'ExampleSuite',
          architecture:'v.1.0',
          description:'Example Description',
          downloads:'21k',
          version:'1.0.3'
        }} />
      </div>
      <div>
      <SuiteCardHome suite={{
          scope:'PUBLIC',
          imageURL:'https://5.imimg.com/data5/SELLER/Default/2021/7/NE/LV/HH/48426159/samsung-galaxy-fold-mobile-phone.jpg',
          suiteName:'ExampleSuite',
          architecture:'v.1.0',
          description:'Example Description',
          downloads:'21k',
          version:'1.0.3'
        }} />
      </div>
      <div>
      <SuiteCardHome suite={{
          scope:'PUBLIC',
          imageURL:'https://5.imimg.com/data5/SELLER/Default/2021/7/NE/LV/HH/48426159/samsung-galaxy-fold-mobile-phone.jpg',
          suiteName:'ExampleSuite',
          architecture:'v.1.0',
          description:'Example Description',
          downloads:'21k',
          version:'1.0.3'
        }} />
      </div> */}
        {/* <div>
        <img className="d-block"
          src={land_suite}
          alt="First slide"
        />
      </div> */}

      </Slider>
    </div>
  );
}
export default SimpleSlider;


