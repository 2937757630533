
import { useImperativeHandle, useState, useEffect, forwardRef } from 'react'
import { Form, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { BiCamera } from "react-icons/bi";
import PreviewImage from '../../utility/Preview';


function CreateSuiteForm3({ suite }, ref) {

  // const [selectedImage, setSelectedImage] = useState(null);
  // const [previewImage, setPreviewImage] = useState(null);
  // console.log(suite)
  const [suiteExist,setSuiteExist] = useState(false)

  const initialValues = {
    suiteName: '',
    version: '',
    description: '',
    imageFile: '',
    scope: '',
    architecture: '',
  }


  const onSubmit = values => {
    const suite = new FormData();
    Object.keys(values).forEach(key => suite.append(key, formik.values[key]));
    console.log(values)
    return (suite)
  }

  const validationSchema = yup.object({
    suiteName: yup.string().min(3).max(20).required('Provide a valid Name!')
      // .test("suiteName", "Enter a different suitename", () => !suiteExist ? true : false)
      ,
    version: yup.string().required('Enter your system version !'),
    description: yup.string().max(100).required('Please provide a valid description!'),
    architecture: yup.string().required('Please enter a valid architecture').oneOf(['linux/amd64', "linux/arm64/v8"]),
    imageFile: yup.mixed().notRequired()
      .required('Add an Image!'),
    // .test("File Type","Only image(.jpeg ,.jpg and .png) supported!", value => value && ['image/png','image/jpeg','image/jpg'].includes(value.type))
    // .test("File Size","File size too big! Try to upload a file less than 5 Mb .", value =>value && value.size < 1024 * 1024 * 1024 * 5),
    scope: yup.string().required().oneOf(['PRIVATE', 'PUBLIC', 'ORGANISATION'])
  });


  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  useImperativeHandle(ref, () => {
    return {
      validated: () => Object.keys(formik.errors).length === 0
      ,
      Submit: async () => {
        const suiteForm = await formik.submitForm();
        return suiteForm
      }
      ,
      Values: () => {
        const suite = new FormData();
        Object.keys(formik.values).forEach(key => suite.append(key, formik.values[key]));
        console.log(formik.values)
        return (suite)
      },
      lockForm:()=>setSuiteExist(true)
      
    }
  });

  useEffect(()=>{

    formik.setFieldValue('suiteName',suite?.suiteName)
    formik.setFieldValue('description',suite?.description)
    formik.setFieldValue('version',suite?.version)
    formik.setFieldValue('architecture',suite?.architecture)
    formik.setFieldValue('scope',suite?.scope)

  },[suite])

  // console.log(suite,suiteExists)

  return (

    <Form onSubmit={formik.handleSubmit} >
      {/* {console.log(formik)} */}
      <Row>
        <Col md={6} lg={4} xl={3}>
          <div className='custom_upload position-relative mt-3 mb-4'>
            {
              !suite &&
              <Form.Control
                type="file"
                className='position-absolute bottom-0 end-0 translate-middle'
                name='imageFile'
                onChange={e => {
                  const file = e.target.files[0];
                  formik.setFieldValue('imageFile', file)
                  }
                }
                // disabled={suite || (!formik.errors.imageFile && !suiteExist )}
              // isInvalid={!suite &&(formik.touched.imageFile && formik.errors.imageFile)}
              // isValid={!suite && formik.touched.imageFile}
              />
            }
            {
              suite ?
                <img src={`${suite.image}`} alt="Preview" width={200} className='rounded-pill' />
                :
                <>
                  <PreviewImage file={formik?.values?.imageFile} />
                  <span><BiCamera /></span>
                </>

            }
            {/* {selectedImage && previewImage && <img src={previewImage} alt="Preview" width={200} className='rounded-pill' />} */}

            {/* {console.log(!formik.errors.suiteName && formik.submitCount >=1 && suiteExists,115)} */}
          </div>
          <p className="text-center text-danger">{(formik.touched.imageFile && formik.errors.imageFile)}</p>
        </Col>
        <Col md={6} lg={8} xl={9}>
          <Form.Label>Enter Suite Details</Form.Label>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <div className='d-flex'>
              <Form.Control
                type="text"
                className='me-2'
                name='suiteName'
                placeholder={suite ? suite?.suiteName : 'suite name'}
                disabled={suite || (!formik.errors.suiteName && suiteExist )}
                // value={x}
                value={formik?.values?.suiteName || ''}
                onChange={formik.handleChange}
                isInvalid={!suite && (formik.touched.suiteName && formik.errors.suiteName)}
                isValid={!suite && formik.touched.suiteName}
              />
              <Form.Control.Feedback type='invalid' >
                {formik.errors.suiteName}
              </Form.Control.Feedback>

              <Form.Control
                type="text"
                className='me-2'
                name='version'
                placeholder={suite ? suite?.version : 'version'}
                disabled={suite || (!formik.errors.version && suiteExist)}
                value={formik?.values?.version || ''}
                onChange={formik.handleChange}
                isInvalid={!suite && (formik.touched.version && formik.errors.version)}
                isValid={!suite && formik.touched.version}
              />
              <Form.Control.Feedback type='invalid' >
                {formik.errors.version}
              </Form.Control.Feedback>
              <div className="custom-select-container">
                <Form.Select
                  name='architecture'
                  disabled={suite || (!formik.errors.architecture && suiteExist)}
                  // value={suite?.architecture || 'a'}
                  onChange={formik.handleChange}
                  className="custom-select"
                  isInvalid={!suite && (formik.touched.architecture && formik.errors.architecture)}
                  isValid={!suite && formik.touched.architecture}
                  defaultValue={formik?.values?.architecture || "a"}
                >
                  <option disabled value="a" >Architecture</option>
                  <option value="linux/amd64" >linux/amd64</option>
                  <option value="linux/arm64/v8" >linux/arm64/v8</option>
                </Form.Select>
              </div>

            </div>
          </Form.Group>


          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">

            <Form.Control
              as="textarea"
              name="description"
              placeholder={suite ? suite?.description : 'description'}
              disabled={suite || (!formik.errors.description && suiteExist)}
              rows={3}
              value={formik?.values?.description || ''}
              onChange={formik.handleChange}
              isInvalid={!suite && (formik.touched.description && formik.errors.description)}
              isValid={!suite && formik.touched.description}
            />
            <Form.Control.Feedback type='invalid' >
              {formik.errors.description}
            </Form.Control.Feedback>

          </Form.Group>

          <Form.Group>

            <Form.Label>Choose scope</Form.Label>
            <div className="mb-3">
              {['PRIVATE', 'PUBLIC', 'ORGANISATION'].map((scope) => (
                <Form.Check
                  inline
                  label={scope}
                  name='scope'
                  value={scope}
                  type='radio'
                  key={scope}
                  disabled={suite || (!formik.errors.scope && suiteExist)}
                  onChange={formik.handleChange}
                  isInvalid={!suite && (formik.touched.scope && formik.errors.scope)}
                  isValid={!suite && formik.touched.scope}
                  checked={suite && suite?.scope===scope }
                />
              ))}

            </div>
          </Form.Group>
        </Col>

      </Row>
    </Form>


  )
}

export default forwardRef(CreateSuiteForm3) 