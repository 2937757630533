import { useState } from 'react'
import { Card, Col, ListGroup, Row, Image } from 'react-bootstrap';
import { device1, verified } from '../Images/Images';
import { ReactSVG } from 'react-svg';

const AppSelectCard = ({ app, searchActive, searched_term }) => {
  let left_parted_name = searched_term && app?.appName?.slice(0, app?.appName?.indexOf(searched_term))
  let right_parted_name = searched_term && app?.appName?.slice(app?.appName?.indexOf(searched_term) + searched_term.length)
  return (
    <>
      {/* <button className=" focus-ring focus-ring-info text-decoration-none p-0 border rounded-2"> */}
        <Card key={app?.appId} className="border-0 focus-ring focus-ring-info shadow-sm border"  >
          <div className="ribbon">
            {/* <div className="wrap"> */}
            <span className={app?.scope === "PUBLIC" ? "tag public" : app?.scope === "PRIVATE" ? "tag private" : "tag org"}>
              {app?.scope === "ORGANISATION" ? 'ORG' : app?.scope}
            </span>
            {/* <span className={app?.scope === "PUBLIC" ? "ribbon6 public" : app?.scope === "PRIVATE" ? "ribbon6 private" : "ribbon6 org"}>
            { app?.scope === "ORGANISATION" ? 'ORG' : app?.scope }
            </span> */}
            {/* </div> */}
          </div>
          <Card.Body className='p-0'>
            <div className='img-box text-center position-relative'>
              {/* <Card.Img variant="top"  src={ `${process.env.REACT_APP_IMAGE}${app?.imageUrl}`} /> */}
              <Image width={100} roundedCircle src={`${app?.imageData}`} />
              <ReactSVG className='position-absolute bottom-0 end-0 verified' src={verified} />
            </div>
            {
              searchActive ? <><Card.Title className='fs-6'>

                <span className='text-center '>{left_parted_name}</span>
                <span className='text-center text-primary'>{searched_term}</span>
                <span className='text-center '>{right_parted_name}</span>
              </Card.Title></>
                :
                <Card.Title className='text-center fs-6'>{app?.appName}</Card.Title>
            }
            {/* <Card.Title className='text-center fs-6'>{app?.appName}</Card.Title> */}

            <Card.Text className='fs-6n info fw-light d-flex justify-content-center justify-content-between px-3 mb-2'>
              <span className='me-2 border-right text-truncate'>{app?.user?.username}</span>
              <span className='me-2'>downloads</span>
              <span>{app?.version}</span>
            </Card.Text>
          </Card.Body>

        </Card>
        {/* <div>
          <button>+</button>
          <output>0</output>
          <button>-</button>
        </div> */}

      {/* </button> */}
    </>
  )

}

export default AppSelectCard