import React, { useState } from 'react'
import { ReactSVG } from 'react-svg';
import { vertical_icon, verified, edit, trash, clone } from '../Images/Images';
import { Dropdown, Card, ListGroup, Button } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating'
import { BiHeart } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import Heart from "react-animated-heart";


const SuiteCardHome = ({ suite }) => {


  const [rating, setRating] = useState(0);
  const [show, setShow] = useState(false)
  const navigate = useNavigate()
  const [isClick, setClick] = useState(false);
  // console.log(suite)

  return (
    <>
      <div className='suit_item mb-5 '>
        <Card className='shadow border-0'>
          <Card.Body className='p-4'>
            <div className="d-flex justify-content-between mb-3">
              <div className="ribbon">
                <span className={suite && suite.scope === "PUBLIC" ? "tag public" : suite.scope === "PRIVATE" ? "tag private" : "tag org"}>
                  {suite && suite.scope === "ORGANISATION" ? 'ORG' : suite.scope}
                </span>
                {/* <div className="wrap">
                  <span className={suite && suite.scope === "PUBLIC" ? "ribbon6 public" : suite.scope === "PRIVATE" ? "ribbon6 private" : "ribbon6 org"}>
                    {suite && suite.scope === "ORGANISATION" ? 'ORG' : suite.scope}
                  </span>
                </div> */}
              </div>
              <div className="vertical-icon d-flex">
                <Button variant='link fs-3 heart mt-1'>
                  {/* <BiHeart /></Button> */}
                  <Heart styles={{ margin: '-27px -30px -40px -40px' }} isClick={isClick} onClick={() => setClick(!isClick)} /></Button>
                <ReactSVG className='mt-3' src={vertical_icon} />
                {/* <Dropdown align="end">
                  <Dropdown.Toggle variant="default" id="dropdown-basic" className='bg-white border-light border-0 p-0'>
                    <ReactSVG src={vertical_icon} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='py-2 shadow border-light'>
                    <Dropdown.Item className='d-flex' ><ReactSVG className='me-2' src={clone} />
                      <button className='bg-transparent border-0 text-body'>
                        Clone
                      </button>
                    </Dropdown.Item>
                    <Dropdown.Item className='d-flex' ><ReactSVG className='me-2' src={edit} /> Edit Suite</Dropdown.Item>
                    <Dropdown.Item className='d-flex'><ReactSVG className='me-2' src={trash}

                    />
                      <button className='bg-transparent border-0 text-body' onClick={() => setShow(true)}>
                        Delete Suite
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <div className="device-img position-relative">
                <Card.Img
                  className='img-2 rounded-pill shadow'
                  variant="top"
                  src={`${suite?.image}`}
                //  onClick={()=>navigate(`suite/demo/${suite?.suiteUid}`)}
                />
                <ReactSVG src={verified} className="position-absolute verified bottom-0 end-0" />
              </div>

            </div>


            <Card.Title
              // onClick={()=>navigate(`suite/demo/${suite?.suiteUid}`)}
              className='card-title h3 my-2 mt-3 text-left' >
              {suite?.suiteName}
            </Card.Title>




            {['sm'].map((breakpoint) => (
              <ListGroup key={breakpoint} horizontal={breakpoint} className="my-0 d-flex justify-content-between">
                <ListGroup.Item className='p-0 border-0 border-right me-2'>{suite?.user?.username}</ListGroup.Item>
                <div className="vr"></div>
                <ListGroup.Item className='p-0 border-0 border-right me-2'>{suite && suite.downloads} </ListGroup.Item>
                <div className="vr"></div>
                <ListGroup.Item className='p-0 border-0 border-right'>{suite && suite.version}</ListGroup.Item>
              </ListGroup>
            ))}

            <div className="d-flex justify-content-between rating mt-2">
              <Rating
                initialValue={'5'}
                // onClick={(rate) => setRating(rate)}
                // onPointerEnter={() => console.log('Enter')}
                // onPointerLeave={() => console.log('Leave')}
                // onPointerMove={(value, index) => console.log(value, index)}
                fillColor={'#0C90E1'}
                size={'17'}
              /* Available Props */
              />
              <div className="vr"></div>
              <Card.Text className='ms-2 mt-1'>
                {suite && suite.architecture}
              </Card.Text>

            </div>
            <Card.Text className='mt-2 desc-text text-left desc-text mh-25' >
              {suite && suite.description}
            </Card.Text>

          </Card.Body>
        </Card>
      </div>



    </>

  )
}

export default SuiteCardHome