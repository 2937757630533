import React from 'react'
import {
  Link,
  NavLink,
  useNavigate
} from "react-router-dom";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { FiExternalLink } from "react-icons/fi";
import { SiGrafana } from "react-icons/si";

import { ReactSVG } from 'react-svg';
import { home, logo, short_logo, appstore, device, suits, user_profile, notification, help } from '../Images/Images';


function Sidebar({ user, logOut, userID }) {
  const navigate = useNavigate()
  return (

    <div className='side-navbar bg-white d-flex justify-content-between flex-wrap flex-column border-end z-2'>
      <div className="d-flex flex-column flex-shrink-0 p-3 px-3">
        <div className="d-flex justify-content-between">
          <Link className='logo only-desktop' to='/'>
            <ReactSVG
              src={logo}
              alt=""
              onClick={() => navigate('/')}
            /></Link>
          <Link className='logo only-devices' to='/'>
            <ReactSVG
              src={short_logo}
              alt=""
              onClick={() => navigate('/')}
            /></Link>
        </div>

        <ul className="nav nav-pills flex-column mb-auto mt-5">
          <li>
            <NavLink
              className={'nav-link link-dark d-flex flex-wrap align-items-center'}
              to="/dashboard"
              // to="#"
              data-toggle="tooltip" title="Coming Soon ...">
              <ReactSVG src={home} className="me-2" />
              <span>Cockpit</span>
            </NavLink>

          </li>
          <li>
            <NavLink
              className={'nav-link link-dark d-flex flex-wrap align-items-center'}
              to="/appstore"
            >
              <ReactSVG src={appstore} className="me-2" />
              <span>App Store</span>
            </NavLink>

          </li>
          <li>
            <NavLink
              className={'nav-link link-dark d-flex flex-wrap align-items-center'}
              to="/suite"
            >
              <ReactSVG src={suits} className="me-2" />
              <span>Suites</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={'nav-link link-dark d-flex flex-wrap align-items-center'}
              to="/device"
            >
              <ReactSVG src={device} className="me-2" />
              <span>Devices</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={'nav-link link-dark d-flex flex-wrap align-items-center'}
              to={"http://www.nearedge.digital:3000/".concat(userID)} target="_blank"
              data-toggle="tooltip" title="Powered by Grafana">
              {/* <ReactSVG src={help} className="me-2" /> */}
              <div className="me-2">
                <MdOutlineDashboardCustomize size={24}/>
                {/* <SiGrafana size={24}/> */}
              </div>
              <span>Dashboarding</span>
              <FiExternalLink style={{ marginLeft: "50px" }} />
            </NavLink>
          </li>
          <hr className="my-5" />
          {/* <h6 className="ms-3 Settings_title">Settings</h6>           
            <li>
            <NavLink
            className={'nav-link link-dark d-flex flex-wrap align-items-center'}
              to="/"              
            >
              <ReactSVG src={user_profile} className="me-2" /> 
              <span>User</span>
            </NavLink>              
            </li>
            <li>
            <NavLink
            className={'nav-link link-dark d-flex flex-wrap align-items-center'}
              to="/"              
            >
              <ReactSVG src={notification} className="me-2" /> 
              <span>Notification</span>
            </NavLink>              
            </li>
            <li>
            <NavLink
            className={'nav-link link-dark d-flex flex-wrap align-items-center'}
              to="/"              
            >
              <ReactSVG src={help} className="me-2" /> 
              <span>Help</span>
            </NavLink>              
            </li> */}
        </ul>


      </div>
      {/* <div className="text-center p-3 border-top small copyright">
          © 2023 Copyright: The Open Factory    
        </div> */}
      <footer className='text-center p-3 border-top small copyright only-desktop'>
        <p>© 2024 : The OpenFactory</p>
      </footer>
      <footer className='text-left p-3 border-top small copyright only-devices'>
        <p className='m-0 p-0'>© 2024</p>
      </footer>
    </div>
  )
}

export default Sidebar